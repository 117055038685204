<template>
  <div>
    <modal :show="showModal" :alert="alert" @update="updateValue"/>
    <b-card title="Vous pouvez modifier les alerts de blog directement ici.">
      <b-row class="match-height">
        <b-col
          md="3"
          lg="3"
          class="mb-4"
        >
          <b-button
            variant="primary"
            @click="createAlert()"
          >
            Créer une nouvelle alerte
          </b-button>
        </b-col>
        <b-col
          cols="3"
          md="3"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <v-select
            v-model="country"
            placeholder="Pays"
            label="Pays"
            :options="countryOptions"
            :clearable="false"
            class="invoice-filter-select"
          />
        </b-col>
      </b-row>
      <b-row class="match-height">
        <b-col
          v-for="alert in alerts"
          :key="alert.id"
          md="3"
          lg="3"
          class=""
        >
          <b-card
            v-if="alert"
            class="ecommerce-card"
            no-body
          >
            <div
              v-if="alert.imgSrc"
              class="item-img text-center"
            >
              <b-img
                fluid
                class="card-img-top"
                style="height: 180px; object-fit: cover"
                :src="alert.imgSrc"
              />
            </div>

            <!-- alert Details -->
            <b-card-body>
              <h4 class="item-name">
                {{ alert.title }}
              </h4>
              <!-- <b-card-text class="item-description">
                {{ alert.short_description }}
              </b-card-text> -->
            </b-card-body>

            <!-- alert Actions -->
            <div class="item-options text-center">
              <b-button
                variant="flat-primary"
                tag="a"
                class="btn-cart mb-1"
                @click="openModal(alert)"
              >
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                />
                <span>Modifier l'alerte</span>
              </b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BButton,
  // BFormTextarea,
} from 'bootstrap-vue'
import { Quill } from 'vue-quill-editor'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import Modal from '@/components/alert/Modal.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BButton,
    BCardBody,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue,
    Modal,
  },
  watch: {
    country: {
      deep: true,
      handler: 'getAlerts',
    },
  },
  data() {
    return {
      alerts: [],
      alert: {},
      showModal: false,
      options: { mode: 'code' },
      blocs: [],
      productSelect: [],
      productId: null,
      firstContent: '',
      country: 'FR',
      countryOptions: ['ES', 'FR', 'DE', 'IT', 'NL', 'EN'],
      snowOption: {
        theme: 'snow',
        modules: {
          toolbar: [['bold', 'italic', 'underline'], [{ list: 'ordered' }, { list: 'bullet' }], ['link']],
        },
      },
      whithoutOption: {
        theme: 'snow',
        modules: {
          toolbar: [],
        },
      },
    }
  },
  async mounted() {
    try {
      const Block = Quill.import('blots/block')
      Block.tagName = 'div'
      Quill.register(Block, true)
      await this.getAlerts()
    } catch (err) {
      console.log(err)
    }
  },
  methods: {
    async getAlerts() {
      try {
        const { alerts } = await this.$http.get(`/admin/alerts/?country=${this.country}`)
        this.alerts = alerts
      } catch (err) {
        console.log(err)
      }
    },
    async createAlert() {
      this.alert = {
        imgSrc: '',
        type: '',
        title: '',
        view: false,
        buttonTitle: '',
        buttonLink: '',
        description: '',
      }
      this.blocs = []
      this.firstContent = ''
      this.productId = null
      this.showModal = true
    },
    async openModal(alert) {
      this.showModal = true
      this.alert = alert
    },
    convertHtml(html) {
      // eslint-disable-next-line newline-per-chained-call
      return html?.replaceAll('<span style="color: rgb(0, 0, 0);">', '')?.replaceAll('</span>', '')?.replaceAll('style="color: rgb(0, 0, 0);"', '')
    },
    async updateValue() {
      await this.getAlerts()
      this.showModal = false
    },
    errorJson(err) {
      console.log(err)
    },
  },
}
</script>

<style>
 .jsoneditor-poweredBy {
   display: none;
 }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
